import "./Tag.scss";
import PropTypes from "prop-types";
import checkmark from "./Checkmark.svg";

export const Tag = ({
    textArray
}) => {
        return (
            textArray.map((text) => {
                return (
                    <div className="chip" key={text}>
                        <img src={checkmark} alt="checkmark" />
                        {text}
                    </div>
                )
            })
        )
    }

Tag.propTypes = {
    textArray: PropTypes.arrayOf(PropTypes.string)
}