import { Button, Label } from "../index";
import "./Header.scss";

export const Header = ({
    target
}) => {
    return (
        <div>
            <div className="nav-header">
                <div className="logo">
                    <h4>SAFE AUTONOMOUS AI</h4>
                    <img src={`${process.env.PUBLIC_URL}/svgs/logo.svg`} alt="target"></img>
                </div>
                <div className="nav">
                    <Label path="/" text="Home" type="1" />
                    <Label text="Services" type="1"  labels={["Drone", "eVtol", "Car", "Self Driving Car", "Jet"]} subPaths={["Drone", "evtol", "Car", "selfdrive", "Jet"]} />
                    {/*<Label path="/About" text="About" type="1" />*/}
                    <Button text="Contact Us" type="secondary" size="small" buttonAction="/contactus" />
                </div>
            </div>
        </div>
    )
}