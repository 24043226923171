import "./Label.scss";

export const Label = ({
    text,
    path,
    type,
    subPaths,
    labels
}) => {
    return (
        <span className={`label-${type}`}>
            {
                !!path &&
                <a href={path}>{text}</a>
            }
            {
                !path && !subPaths && text
            }
            {
                !path && !!subPaths && 
                <div className="subnav">
                    <div className="subnavbtn">
                        {text}
                    </div>
                    <div className="subcontent">
                        <a className="nav-link" href={`/services/${subPaths[0]}`}>{labels[0]}</a>
                        <a className="nav-link" href={`/services/${subPaths[1]}`}>{labels[1]}</a>
                        <a className="nav-link" href={`/services/${subPaths[2]}`}>{labels[2]}</a>
                        <a className="nav-link" href={`/services/${subPaths[3]}`}>{labels[3]}</a>
                        <a className="nav-link" href={`/services/${subPaths[4]}`}>{labels[4]}</a>
                    </div>
                </div>
            }
        </span>
    )
}