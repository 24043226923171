import { Button } from "../index.js";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { Icon } from "./../index.js";
import { Col, Container, Row } from 'react-grid-system';

export const Footer = () => {
    return (
        <div className="footer">
            <div><h2 className="header">Get in Touch</h2></div>
            <div><h4>Get a safety upgrade.</h4></div>
            <div><Button text="Contact Us" type="secondary" size="small" buttonAction="/contactus" /></div>
            <Container className="two-columns">
                <Row>
                    <Col sm={4}>
                        <div className="title">
                            <h3>SAFE AUTONOMOUS AI</h3>
                            <Icon fileName="logo" color="white"/>
                        </div>
                    </Col>
                    <Col sm={8}>
                        <Container>
                            <Row>
                                <Col lg={4} className="column">
                                    <h5>Vehicle</h5>
                                    <Link to="/services/drone">Drone</Link>
                                    <Link to="/services/evtol">eVtol</Link>
                                    <Link to="/services/selfdrive">Self Driving</Link>
                                    <Link to="/services/car">Car</Link>
                                    <Link to="/services/jet">Jet</Link>
                                </Col>
                                <Col lg={4} className="column">
                                    <h5>Follow Us</h5>
                                    <Link target="_blank" to="https://www.facebook.com/safeairmobility">Facebook</Link>
                                    <Link target="_blank" to="https://twitter.com/SafeAirMobility">Twitter</Link>
                                    <Link target="_blank" to="https://www.instagram.com/safeairmobility/">Instagram</Link>
                                    <Link target="_blank" to="https://www.linkedin.com/company/safe-air-mobility/">LinkedIn</Link>
                                </Col>
                                <Col lg={4} className="column">
                                    <h5>Company</h5>
                                    {/*<Link to="about">About Us</Link>*/}
                                    <Link to="">News</Link>
                                    <Link to="/contactus">Contact Us</Link>
                                    <Link to="">FAQs</Link>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            {/* <div><span>© 2023 Safer Autonomous Mobility, Inc.</span></div> */}
        </div>
    )
}