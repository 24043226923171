import "./IconLabel.scss";
import { Icon } from  "./../index.js";

export const IconLabel = ({
    fileName, info
}) => {
    return (
        <div className="icon-label">
            <div className="icon-image">
                <Icon fileName={fileName} />
            </div>
            <div className="icon-text">
                {info}
            </div>
        </div>
    )
}