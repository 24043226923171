import React from 'react';
import "./ContactUs.scss";
import { Container, Col, Row } from "react-grid-system";
import { Button } from './../../controls/index';
import { Carousel } from 'react-responsive-carousel-nugget';

export class ContactUs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            industriesUseCases: ["Vertiport", "Transportation", "Commercial", "Insurance", "Construction", "Aviation", "Governmental", "Regulatory Compliance"],
            serviceIndex: 1
        }
    }

    updateServiceIndex = (index) => {
        this.setState({serviceIndex: index})
    }

    render () {
        return (
        <div className="section contact-us" id="contact-us">
            <div className="main">
                <h1>Get Started with SAM</h1>
                <h4>Select vehicle type below</h4>
                <Carousel infiniteLoop={true} showIndicators={false} swipeable={true} showThumbs={false} showStatus={false}  onChange={(index, item) => this.setState({serviceIndex: index + 1})}>
                    <div>
                        <img src={`${process.env.PUBLIC_URL}images/drone-2.png`} alt="service" />
                        <h4>Drone</h4>
                    </div>
                    <div>
                        <img src={`${process.env.PUBLIC_URL}images/evtol-1.png`} alt="service" />
                        <h4>eVtol</h4>
                    </div>
                    <div>
                        <img src={`${process.env.PUBLIC_URL}images/jet-2.png`} alt="service" />
                        <h4>Jet</h4>
                    </div>
                    <div>
                        <img src={`${process.env.PUBLIC_URL}images/car.png`} alt="service" />
                        <h4>Car</h4>
                    </div>
                    <div>
                        <img src={`${process.env.PUBLIC_URL}images/driverless.png`} alt="service" />
                        <h4>Driverless Car</h4>
                    </div>
                </Carousel>
            </div>
            <div className="main contact-form">
                <h2>Contact Us</h2>
                <form action="mailto:sam@safeairmobility.com" method="post" enctype="text/plain">
                    <Container>
                        <Row>
                            <Col lg={12} xl={6}>
                                <label for="firstName">First Name</label>
                                <input type="text" id="firstName" />
                            </Col>
                            <Col lg={12} xl={6}>
                                <label for="lastName">Last Name</label>
                                <input type="text" id="lastName" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label for="email">Email</label>
                                <input type="text" id="email"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label for="phone">Phone Number</label>
                                <input type="text" id="phone" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label for="description">What is your use case? Please briefly describe what you do with autonomous vehicles</label>
                                <input type="text" id="description" />
                            </Col>
                        </Row>
                        <input type="hidden" id="serviceId" value={this.state.serviceIndex} />
                        <Button text="Submit" type="primary" size="small" />
                    </Container>
                </form>
            </div>
        </div>
    )}
}