import React from 'react';
import "./Home.scss";
import { Bubble, Button, Card, IconLabel, Label, Tag } from "./../../controls/index";
import { Col, Container, Row } from 'react-grid-system';
import { ContactUs } from './../ContactUs/ContactUs.jsx';
import "react-responsive-carousel-nugget/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel-nugget';

export class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            industriesUseCases: ["Vertiport", "Transportation", "Commercial", "Insurance", "Construction", "Aviation", "Governmental", "Regulatory Compliance"]
        }
    }
    render () {

        return (
            <div className="home-container">
                <div className="section header-container">
                    <div className="text-container">
                        <div className="header-text">
                            <div><h1>Autonomous Safety Reimagined</h1></div>
                            <div><h4>We provide an integrated set of tools for autonomous vehicle risk assessment and management.</h4></div>
                        </div>
                        <div className="button-container">
                            <Button text="Request a Demo" type="primary" size="large" />
                            <Button text="Contact Us" type="secondary" size="large" buttonAction="contactus" />
                        </div>
                    </div>
                </div>
                <div className="section offerings-container">
                    <h1>The next generation mobility safety product for autonomous sectors</h1>
                    <h3>Industry-leading safety and risk assessment across multiple vehicle spaces.</h3>
                    <div className="main-bubble-container">
                        <Bubble imageName="Drone-1.png" label="Drone" route="/services/drone"/>
                        <Bubble imageName="evtol.png" label="eVtol" route="/services/evtol"/>
                        <Bubble imageName="Tesla-1.png" label="Car" route="/services/car"/>
                        <Bubble imageName="waymo-1.png" label="Self Driving Car" route="/services/selfdrive"/>
                        <Bubble imageName="Jet-1.png" label="Jet" route="/services/jet"/>
                    </div>
                </div>
                <div className="section enhancing">
                    <div>
                        <div><h2>Enhancing Safety Across Industries</h2></div>
                        <div><Label type="2" text="Use Cases" /></div>
                        <div><Tag textArray={this.state.industriesUseCases} /></div>
                        <div><span className="large">We provide a variety of safety assessment for autonomous vehicle developers, fleet owner-operators, technology vendors, and service providers across different industry sectors.</span></div>
                        </div>
                    <div className="button-container">
                        <Button text="Request a Demo" type="primary" size="large" />
                        <Button text="Contact Us" type="secondary" size="large" buttonAction="contactus" />
                    </div>
                </div>
                <div className="section how">
                    <div>
                        <h1>How our solutions can help you</h1>
                        <div className="how-cards">
                            <Card type="1" imgName="Drone" title="For Pilots: Navigate with Confidence ">
                            Our flight safety app helps drone & eVTOL operators to safely navigate urban operations by providing flight safety assessments safe route optimization.
                            </Card>
                            {/* TODO: Update text below */}
                            <Card type="1" imgName="InsuranceCompany" title="For Insurance Companies: Provide Confident Advice">
                            Our enterprise solution is designed to provide intelligent risk analytics for eVTOL & drone operations. With our underwriting solutions, insurance companies can assess drone and eVTOL flight risks with confidence.
                            </Card>
                            <Card type="1" imgName="UrbanPlanner" title="For Urban Planners: Locating Vertiports & Analyzing eVTOL & Drone Urban Impact Made Easy.">
                            Our app analyzes vertiports' urban impacts. Vertiport providers and operators can easily assess the flight and operation risks of drones and eVTOL.
                            </Card>
                        </div>
                    </div>
                </div>
                <div className="section sam-app">
                    <div>
                        <div><h1>Introducing the SAM App</h1></div>
                        <div><Label type="2" text="Software" /></div>
                        <div><h5>Our flagship app helps users understand drone & eVTOL flight risks, underwrite the risks intelligently & optimize flight paths in an interactive web-based platform.</h5></div>
                    </div>
                    <div className="sam-features">
                        <IconLabel fileName="heartrate_clipboard" info="Autonomous Mobility Safety Scorecard" />
                        <IconLabel fileName="shield" info="Risk Mitigation Strategies" />
                        <IconLabel fileName="robot" info="AI-powered Insurance Optimization Engine " />
                    </div>
                    <div className="sam-examples">
                        <Card type="2" imgName="GisAnalysis" title="Real-Time GIS Analysis">
                        Users may select a polygon area or specific flight path on our GIS interface. Our app will predict real-time traffic and weather-related issues and suggest safer, more efficient travel alternatives.
                        </Card>
                        <Card type="2" imgName="ImpactFactors"  title="Impacting Factors">
                        SAM's algorithm is based on 30+ historic and live databases containing thousands of data points, including pedestrian and vehicle traffic, weather, population density, no-fly zones, and flight ambient data.
                        </Card>
                        <Card type="2" imgName="RiskAnalysis"  title="Customized Risk Result">
                        Our app's risk analysis feature provides users with personalized risk assessments based on real-time data and individual travel patterns. This feature helps users make informed decisions, enhancing their safety and ensuring a more secure travel experience.
                        </Card>
                    </div>
                </div>
                <ContactUs />
            </div>
        )
    }
}
