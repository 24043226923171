import React from 'react';
import "./About.scss";
import hashemImage from './hashem.jpeg';
import BingImage from './bingq.jpeg';
import { Bubble, Button, Card, IconLabel, Label, Tag } from "./../../controls/index";
import { Carousel } from 'react-responsive-carousel-nugget';


export class About extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            industriesUseCases: ["Vertiport", "Transportation", "Commercial", "Insurance", "Construction", "Aviation", "Governmental", "Regulatory Compliance"],
            serviceIndex: 2
        }
    }

    updateServiceIndex = (index) => {
        this.setState({serviceIndex: index})
    }

    render () {
        return (
        // <div className="section about" id="about">
        
            <div className="section sam-app">
                <div style={{width: '100%', height: '100%', paddingTop: 80, paddingBottom: 30, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: 'inline-flex'}}>
                    <div style={{height: 220, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 30, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', textAlign: 'center', color: '#2D2D2D', fontSize: 45, fontFamily: 'Avenir', fontWeight: '700', wordWrap: 'break-word'}}>Meet Our Exceptional Team of Autonomous Mobility Experts</div>
                        <div style={{alignSelf: 'stretch', textAlign: 'center', color: '#2D2D2D', fontSize: 28, fontFamily: 'Avenir', fontWeight: '350', wordWrap: 'break-word'}}>Our founding team of PhD scientists and engineers place human safety at the forefront.</div>
                    </div>
                </div>



                <div style={{width: '100%', height: '100%', paddingRight: 80, paddingTop: 0, paddingBottom: 80, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'inline-flex'}}>
                    <div style={{height: 76, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', color: '#2D2D2D', fontSize: 56, fontFamily: 'Avenir', fontWeight: '800', wordWrap: 'break-word'}}>Founders</div>
                    </div>
                </div>

                <div style={{width: '100%', height: '100%', justifyContent: 'space-around', flexDirection: 'row', display: 'flex'}}>
                    <div style={{width: 630, height: 820, position: 'relative'}}>
                        <div style={{width: 500, height: 720, left: 0, top: 0, position: 'absolute', background: '#E5EDFF', borderRadius: 40}} />
                        <div style={{padding: 8, left: 30, top: 60, right: 30, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'inline-flex'}}>
                            <div style={{height: 100, width: 450, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 60, display: 'flex'}}>
                                <div style={{alignSelf: 'stretch', height: 226, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
                                    <div style={{color: '#2D2D2D', fontSize: 48, fontFamily: 'Avenir', fontWeight: '800', wordWrap: 'break-word'}}>Hashem I. Moud</div>
                                </div>
                            </div>
                            <img style={{width: 440, height: 440, background: 'linear-gradient(0deg, #DFE1E6 0%, #DFE1E6 100%)', borderRadius: 20}} src={hashemImage} />
                        </div>
                    </div>
                        <div style={{width: 600, height: 820, position: 'relative'}}>
                            <div style={{width: 500, height: 720, left: 0, top: 0, position: 'absolute', background: '#E5EDFF', borderRadius: 40}} />
                            <div style={{padding: 8, left: 30, top: 60, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'inline-flex'}}>
                                <div style={{height: 100, width: 450, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 60, display: 'flex'}}>
                                    <div style={{alignSelf: 'stretch', height: 226, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
                                        <div style={{color: '#2D2D2D', fontSize: 48, fontFamily: 'Avenir', fontWeight: '800', wordWrap: 'break-word'}}>Bing Qi</div>
                                    </div>
                                </div>
                                <img style={{width: 440, height: 440, background: 'linear-gradient(0deg, #DFE1E6 0%, #DFE1E6 100%)', borderRadius: 20}} src={BingImage} />
                            </div>
                        </div>
                </div>
                {/* 
                <div style={{width: '100%', height: '100%', paddingRight: 80, paddingTop: 30, paddingBottom: 80, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'inline-flex'}}>
                    <div style={{height: 76, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', color: '#2D2D2D', fontSize: 56, fontFamily: 'Avenir', fontWeight: '800', wordWrap: 'break-word'}}>Team</div>
                    </div>
                </div>

            

            <div style={{width: '95%', height: '100%', paddingBottom: 100,justifyContent: 'space-around', flexDirection: 'row', display: 'flex'}}>
                
                <div style={{width: '95%', height: '100%', left:0, justifyContent: 'space-around', flexDirection: 'row', display: 'flex'}}>
                    <div style={{width: 296, height: 520, position: 'relative'}}>
                        <div style={{width: 316, height: 520, left: 0, top: 0, position: 'absolute', background: '#E5EDFF', borderRadius: 40}} />
                        <div style={{padding: 8, left: 7, top: 200, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'inline-flex'}}>
                            <img style={{width: 266, height: 257, background: 'linear-gradient(0deg, #DFE1E6 0%, #DFE1E6 100%)', borderRadius: 20}} src="https://via.placeholder.com/266x257" />
                        </div>
                        <div style={{height: 112, left: 15, top: 60, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', height: 112, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
                                <div style={{color: '#2D2D2D', fontSize: 48, fontFamily: 'Avenir', fontWeight: '800', wordWrap: 'break-word'}}>Name</div>
                                <div style={{textAlign: 'center', color: '#0032B2', fontSize: 12, fontFamily: 'Roboto Mono', fontWeight: '400', wordWrap: 'break-word'}}>Leadership</div>
                            </div>
                        </div>
                    </div>
                    
                    <div style={{width: 296, height: 520, position: 'relative'}}>
                        <div style={{width: 316, height: 520, left: 0, top: 0, position: 'absolute', background: '#E5EDFF', borderRadius: 40}} />
                        <div style={{padding: 8, left: 7, top: 200, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'inline-flex'}}>
                            <img style={{width: 266, height: 257, background: 'linear-gradient(0deg, #DFE1E6 0%, #DFE1E6 100%)', borderRadius: 20}} src="https://via.placeholder.com/266x257" />
                        </div>
                        <div style={{height: 112, left: 15, top: 60, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', height: 112, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
                                <div style={{color: '#2D2D2D', fontSize: 48, fontFamily: 'Avenir', fontWeight: '800', wordWrap: 'break-word'}}>Name</div>
                                <div style={{textAlign: 'center', color: '#0032B2', fontSize: 12, fontFamily: 'Roboto Mono', fontWeight: '400', wordWrap: 'break-word'}}>Leadership</div>
                            </div>
                        </div>
                    </div>



                    <div style={{width: 296, height: 520, position: 'relative'}}>
                        <div style={{width: 316, height: 520, left: 0, top: 0, position: 'absolute', background: '#E5EDFF', borderRadius: 40}} />
                        <div style={{padding: 8, left: 7, top: 200, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'inline-flex'}}>
                            <img style={{width: 266, height: 257, background: 'linear-gradient(0deg, #DFE1E6 0%, #DFE1E6 100%)', borderRadius: 20}} src="https://via.placeholder.com/266x257" />
                        </div>
                        <div style={{height: 112, left: 15, top: 60, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'inline-flex'}}>
                            <div style={{alignSelf: 'stretch', height: 112, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
                                <div style={{color: '#2D2D2D', fontSize: 48, fontFamily: 'Avenir', fontWeight: '800', wordWrap: 'break-word'}}>Name</div>
                                <div style={{textAlign: 'center', color: '#0032B2', fontSize: 12, fontFamily: 'Roboto Mono', fontWeight: '400', wordWrap: 'break-word'}}>Leadership</div>
                            </div>
                        </div>
                    </div>
                
                
                
                </div>
                
            </div>
            */}

            
                

                    
            
            </div> 
        

        

    )}
}