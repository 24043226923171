import logo from './logo.svg';
import './App.css';
import { Container } from 'react-grid-system';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Footer, Header } from './controls/index';
import { About, Car, ContactUs, ControlsDemo, Drone, EVtol, Home, Jet, SelfDriving } from './pages/index';

function App() {
  return (
    <Router>
      <Container>
        <Header />
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='contactus' element={<ContactUs />} />
            {/*<Route path='/about' element={<About />} /> */}
            <Route path='/services/car' element={<Car />} />
            <Route path='/services/drone' element={<Drone />} />
            <Route path='/services/evtol' element={<EVtol />} />
            <Route path='/services/jet' element={<Jet />} />
            <Route path='/services/selfdrive' element={<SelfDriving />} />
            <Route path='/controls' element={<ControlsDemo />} />
        </Routes>
        <Footer />
      </Container>
    </Router>
  );
}

export default App;
