import PropTypes from "prop-types";
import "./Button.scss";
import { Link } from "react-router-dom";

export const Button = ({
    text,
    type,
    size, 
    buttonAction
}) => {
    return (
      <div>
        {
          !!buttonAction && 
          <Link to={buttonAction}>
            <button className={`${type} ${size}`}>{text}</button>
          </Link>
        }
        {
          !buttonAction && 
            <button className={`${type} ${size}`}>{text}</button>
        }
      </div>
    );
  }
  
  Button.propTypes = {
    text: PropTypes.string,
    type: PropTypes.oneOf(["primary", "secondary", "tertiary", "footer", "label", "icon"]),
    size: PropTypes.oneOf(["small", "large"])
  };
  