import "./Card.scss";
import { Button, Label } from './../index';

export const Card = ({
    type,
    imgName,
    title,
    subLabel,
    children,
    number='',
    text=''
}) => {
    return (
        <div className={`type-${type}`}>
            {
                type === "4" &&
                <div className="title">
                    <div style={{height: 33, left: 73, top: 0, position: 'relative', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 60, display: 'inline-flex'}}>
                        <div style={{height: 33, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 30, display: 'flex'}}>
                        <div style={{width: 666, color: '#2D2D2D', fontSize: 24, fontFamily: 'Avenir', fontWeight: '350', wordWrap: 'break-word'}}>{text}</div>
                        </div>
                    </div>
                    <div style={{left: 0, top: -45, position: 'relative', color: '#DFE1E6', fontSize: 48, fontFamily: 'Roboto Mono', fontWeight: '500', wordWrap: 'break-word'}}>{number}</div>
                </div>
            }
            <div className="image">
            {
                type === "1" &&
                <img src={`${process.env.PUBLIC_URL}/images/${imgName}.png`} alt="card" />
            }
            {
                type === "2" &&
                <img src={`${process.env.PUBLIC_URL}/images/${imgName}.png`} alt="card" />
            }
            {
                type === "3" &&
                <img src={`${process.env.PUBLIC_URL}/images/${imgName}.gif`} alt="card" />
            }
            {
                type === "4" &&
                <img style={{width: '80%', height: '90%',mixBlendMode: 'darken'}} src={`${process.env.PUBLIC_URL}/images/${imgName}.png`} alt="card"  width="553" height="186"/>
            }
                
            </div>
            <div className="content-info">
                <div>
                    <h3>{title}</h3>
                    { !! subLabel && <div><Label type="2" text={subLabel} /></div> }
                    <span>{children}</span>
                </div>
                <div>
                    {
                        type === "1" &&
                        <Button text="Contact Us" type="secondary" size="small" />
                    }
                    {
                        type === "2" &&
                        <Button text="Learn More" type="tertiary" size="small" />
                    }
                    {
                        type === "3" &&
                        <Button text="Learn More" type="primary" size="small" />
                    }
                </div>
            </div>
        </div>
    )
}