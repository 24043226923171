import { Bubble, Button, Card, Icon, IconLabel, Tag } from "./../../controls/index";

export const ControlsDemo = () => {
    return (
      <div>
          <br/><h1>h1 example</h1>
          <br/><h2>h2 example</h2>
          <br/><h3>h3 example</h3>
          <br/><h4>h4 example</h4>
          <br/><h5>h5 example</h5>
          <span className="large">Body example large</span> <br/>
          <span className="small">Body example small</span>  <br/>
           <br/><Button text="Primary Large" type="primary" size="large" />
           <br/><Button text="Primary Small" type="primary" size="small" />
           <br/><Button text="Secondary Large" type="secondary" size="large" />
           <br/><Button text="Secondary Small" type="secondary" size="small" />
           <br/><Button text="tertiary large" type="tertiary" size="large" />
           <br/><Button text="tertiary Small" type="tertiary" size="small" />
           <br/><Button text="footer" type="footer"/>
           <br/><Tag textArray={["tag 1", "tag 2"]} />
           <br/><Icon fileName="north" />
           <br/><Icon fileName="north_large" color="blue" />
           <br/><IconLabel fileName="heartrate_clipboard" info="Autonomous Mobility Safety Scorecard" />
           <br/><Bubble imageName="Drone-1.png" label="Drone" />
           <br/>
           <Card type="1" imgName="Drone">
                            Our flight safety app helps drone & eVTOL operators safely navigate urban operations by providing flight safety assessments and route optimization.
                            </Card>
                            {/* TODO: Update text below */}
                            <Card type="1" imgName="Database" title="For Insurance Companies: Provide Confident Advice">
                            Our enterprise solution is designed to provide intelligent risk analytics for eVTOL & drone operations. With our underwriting solutions, insurance companies can assess drone and eVTOL flight risks with confidence.
                            </Card>
                            <Card type="1" imgName="Drone" title="For Urban Planners: Locating Vertiports & Analyzing eVTOL & Drone Urban Impact Made Easy.">
                            Our app analyzes vertiports' urban impacts. Vertiport providers and operators can easily assess the flight and operation risks of drones and eVTOL.
                            </Card>
      </div>
    );
  }
  