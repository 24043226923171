import "./eVtol.scss";
import React from 'react';
import { Button, Card, Icon, IconLabel, Label, Tag } from "../../../controls/index";
import "react-responsive-carousel-nugget/lib/styles/carousel.min.css"; // requires a loader
import background from "./evtol_gif.gif"

export class EVtol extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            industriesUseCases: ["Vertiport", "Transportation", "Commercial", "Insurance", "Construction", "Aviation", "Governmental", "Regulatory Compliance"]
        }
    }
    render () {

        return (
            <div className="home-container">
                <div className="page-header">
                    <div style={{width: 1440, height: 174, paddingLeft: 80, paddingRight: 80, paddingTop: 30, paddingBottom: 30, justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex'}}>
                        <div style={{width: 522, color: '#2D2D2D', fontSize: 56, fontFamily: 'Avenir', fontWeight: '800', wordWrap: 'break-word'}}>eVTOL</div>
                        <div style={{width: 453, color: '#2D2D2D', fontSize: 28, fontFamily: 'Avenir', fontWeight: '800', wordWrap: 'break-word'}}>AI-assisted decision-making enhances eVTOL operations for safer air taxi.</div>
                    </div>
                </div>
                <div className="section header-container" style={{ backgroundImage: `url(${background})` }}>
                </div>
                <div className="section enhancing">
                    <div>
                        <div><h2>Enhancing Safety Across Industries</h2></div>
                        <div><Label type="2" text="Use Cases" /></div>
                        <div><Tag textArray={this.state.industriesUseCases} /></div>
                        <div><span className="large">Our flight safety app helps drone operators to safely navigate urban operations by providing flight safety assessments, and safe route optimization.</span></div>
                        </div>
                    <div className="button-container">
                        <Button text="Request a Demo" type="primary" size="large" />
                        <Button text="Contact Us" type="secondary" size="large" buttonAction="contactus" />
                    </div>
                </div>
                <div className="section sam-app">
                    <div>
                        <div><h1>LET US HELP</h1></div>
                        <div><h5>Our SAM App provides user-friendly drone & eVTOL flight risk analytics. Our enterprise solution is designed to provide intelligent risk analytics for drone operations. With our underwriting solutions, insurance companies can assess drone and eVTOL flight risks with confidence.</h5></div>
                    </div>
                    <div className="sam-features">
                        <IconLabel fileName="heartrate_clipboard" info="Risk Assessment" />
                        <IconLabel fileName="heartrate_clipboard" info="Underwriting" />
                        <IconLabel fileName="heartrate_clipboard" info="Insurance " />
                    </div>
                    <div className="sam-features">
                        <IconLabel fileName="heartrate_clipboard" info="Autonomous Mobility Safety Scorecard" />
                        <IconLabel fileName="shield" info="Risk Mitigation Strategies" />
                    </div>
                    <div>
                        <h1>Safety improvement services</h1>
                    </div>
                    <div className="sam-features">
                        <div className="card_row">
                            <div className="card">
                                <div className="card_pic">
                                    <Icon fileName="drone_flight_risk_map" info="Risk Assessment" />
                                </div>
                                <div className="card_text">Customizable eVTOL Flight Risk Map</div>
                            </div>
                        </div>    
                        <div className="card_row">
                            <div className="card">
                                <div className="card_pic">
                                    <Icon fileName="risk_report" info="Risk Assessment" />
                                </div>
                                <div className="card_text">Risk, Safety & Hazard Analysis Report</div>
                            </div>
                        </div>
                        <div className="card_row">
                            <div className="card">
                                <div className="card_pic">
                                    <Icon fileName="optimization_engine" info="Risk Assessment" />
                                </div>
                                <div className="card_text">AI-powered Insurance Optimization Engine</div>
                            </div>
                        </div>
                    </div>

                    <div className="sam-features">
                        <div className="card_row">
                            <div className="card">
                                <div className="card_pic">
                                    <Icon fileName="clipboard" info="Risk Assessment" />
                                </div>
                                <div className="card_text">eVTOL Flight Safety Scorecard</div>
                            </div>
                        </div>    
                        <div className="card_row">
                            <div className="card">
                                <div className="card_pic">
                                    <Icon fileName="shield_protection" info="Risk Assessment" />
                                </div>
                                <div className="card_text">Risk Mitigation Strategies</div>
                            </div>
                        </div>
                        <div className="card_row">
                            <div className="card">
                                <div className="card_pic">
                                    <Icon fileName="map_search" info="Risk Assessment" />
                                </div>
                                <div className="card_text">eVTOL Route Optimization</div>
                            </div>
                        </div>
                    </div>

                    <div className="sam-features">
                        <div className="card_row">
                            <div className="card">
                                <div className="card_pic">
                                    <Icon fileName="operation_analytics" info="Risk Assessment" />
                                </div>
                                <div className="card_text">Advanced eVTOL Operation Analytics</div>
                            </div>
                        </div>    
                        <div className="card_row">
                            <div className="card">
                                <div className="card_pic">
                                    <Icon fileName="database" info="Risk Assessment" />
                                </div>
                                <div className="card_text">Underwriting Solution</div>
                            </div>
                        </div>
                        <div className="card_row">
                            <div className="card">
                                <div className="card_pic">
                                    <Icon fileName="landing_analysis" info="Risk Assessment" />
                                </div>
                                <div className="card_text">Take-off & Landing Location Analysis</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section sam-app-white">
                    <div>
                        <div><h1>Benefits of SAM Technology</h1></div>
                        <div><Label type="2" text="Software" /></div>
                        <div><h5>Our flight safety app helps eVTOL operators to safely navigate urban operations by providing flight safety assessments, and safe route optimization.</h5></div>
                    </div>
                    <div className="sam-examples">
                        <Card type="3" imgName="evtol-1" title="Real-Time GIS Analysis">
                        Integrating real-time GIS analysis into our safety app offers several benefits to our users. It can provide instantaneous updates on traffic, construction zones, and hazardous weather conditions, enabling users to make informed decisions about their routes. By continuously monitoring and analyzing spatial data, the app can predict potential safety issues and suggest safer, more efficient travel alternatives. This real-time information not only enhances user safety but also improves the overall efficiency of transportation networks, making travel more predictable and reducing the risk of accidents.
                        </Card>
                        <Card type="3" imgName="drone-2"  title="Impacting Factors">
                        SAM's algorithm is based on 30+ historic and live databases containing thousands of data points, including pedestrian and vehicle traffic, weather, population density, no-fly zones, and flight ambient data.
                        </Card>
                        <Card type="3" imgName="evtol-3"  title="Customized Risk Result">
                        Our app's risk analysis feature provides users with personalized risk assessments based on real-time data and individual travel patterns. This feature helps users make informed decisions, enhancing their safety and ensuring a more secure travel experience.
                        </Card>
                    </div>
                </div>


                <div className="section how">
                    <div>
                        <h1>SAM Technology in Action</h1>
                        <div><h5>The Version 1 SAM app is available now. It provides a quantitative assessment of drones & eVTOLs flight safety. We are working on Version 2, which will have enhanced features including coverage in Japan & South Korea, urban noise disturbance models & a revamped UI!</h5></div>
                        <div className="how-cards">
                            <Card type="4" number= "01" text= "Data" imgName="action-2">
                            </Card>
                            <Card type="4" number= "02" text= "Map" imgName="action-1">
                            </Card>
                            <Card type="4" number= "03" text= "Risk Analysis" imgName="action-3">
                            </Card>
                        </div>
                    </div>
                </div>

                <div className="section sam-app-small-center">
                    <div>
                        <div className="title">Autonomous mobility is the future</div>
                        <div className="subtext">Don't get left behind. Automate your vehicle safety today.</div>
                    </div>
                    <div>
                    <Button text="Connect with us" type="primary" size="large" />
                    </div>
                </div>
                
                
                
            </div>
        )
    }
}
