import "./Bubble.scss";
import { Link } from "react-router-dom";

export const Bubble = ({
    imageName,
    route,
    label
}) => {
    return (
        <Link to={route}>
            <div className="bubble-outer">
                <div className="bubble-container">
                    <div className="img-container">
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/images/${imageName}`} alt={label} />
                        </div>
                    </div>
                </div>
                <div className="bubble-label">{label}</div>
            </div>
        </Link>
    )
}